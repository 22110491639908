<template>
  <ShareNetwork
    network="linkedin"
    :url="$attrs.url"
    :title="$attrs.title"
    :description="$attrs.description"
  >
    <img class="mt-1 mx-1" src="../../assets/icons/linkedin.svg" width="40" alt="Compartilhar no Linkedin"/>
  </ShareNetwork>
</template>

<script>
export default {

}
</script>

<style>

</style>