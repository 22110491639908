<template>
  <ShareNetwork
    network="whatsapp"
    :title="$attrs.title"
    :url="$attrs.url"
  >
    <img class="mt-1 mx-1" src="../../assets/icons/whatsapp.svg" width="40" alt="Compartilhar no Whatsapp"/>
  </ShareNetwork>
</template>

<script>
export default {

}
</script>

<style>

</style>